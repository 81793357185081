import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  duplicateRulesModalComponent: {
    '& .MuiDialogTitle-root': {
      padding: '1.875rem 1.875rem 0 1.875rem',
    },
    '& .MuiDialogContent-root .MuiGrid-root': {
      paddingTop: '0.5rem',
    },
  },
}));