import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Layout } from 'components';
import { FILTER_PAGE_SOURCE } from 'enums';
import { CUSTOMER_PERMISSIONS } from 'permissions';
import {
  useGetAllPriceRulesById,
  useGetCustomerById,
  useUpdateCustomerPriceRulesMutation
} from 'services';
import { Params, Ruleset } from 'types';
import { RuleTable } from '../components/RuleTable';
import { getPageAppliedFilters } from 'helpers';

export const QuoteRulesPage = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<Params>();
  const customerId = Number(id);

  const appliedFiltersAndSearch = getPageAppliedFilters(FILTER_PAGE_SOURCE.SHIPPER_RULE);

  const { isSuccess: isCustomerSuccess, data: customerData } = useGetCustomerById(customerId);
  const { data: customer } = customerData || {};
  const { isSuccess, isError, isFetching, data: rulesData} = useGetAllPriceRulesById(customerId);
  const { data: rules } = rulesData || {};
  const ruleset = rules?.price_prediction_rules ? [...rules?.price_prediction_rules] : [];

  const { mutate: updateCustomerPriceRules, isLoading: isCustomerPriceRuleUpdating} = useUpdateCustomerPriceRulesMutation(queryClient, {
    onSuccess: () => {
      toast.success('Quoting rules successfully updated!');
    },
    onError: (error) => {
      toast.error('Quoting rules unsuccessfully updated!');
      console.error(error);
    }
  });

  return (
    <Layout
      goBackTo='/shippers'
      title={isCustomerSuccess && `${customer.customer_name} - Quoting Rules`}>
      <RuleTable
        isSuccess={isSuccess}
        isFetching={isFetching}
        isError={isError}
        isLoading={isCustomerPriceRuleUpdating}
        ruleEditorConfig={rulesData?.data?.rule_editor_config}
        ruleset={ruleset}
        handleSaveChanges={(rules: Ruleset) => updateCustomerPriceRules({ id: customerId, price_prediction_rules: rules })}
        permissions={[CUSTOMER_PERMISSIONS.PRICE_RULES_UPDATE]}
        appliedFiltersAndSearch={appliedFiltersAndSearch}
        filterPageSource={FILTER_PAGE_SOURCE.SHIPPER_RULE}/>
    </Layout>
  );
};