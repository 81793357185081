
import React from 'react';
import { Link, Stack } from '@mui/material';
import { Typography } from '@material-ui/core';

import { StatusChip, StopsTooltip } from 'components';
import { CURRENCY_TYPE_LABEL, QUOTE_STATUS_LABELS } from 'enums';
import { formatDateToDashboardPageFormat, formatSeparatelyDateTimeWith12HourClock } from 'helpers';
import { Column, Quote } from 'types';

import { useStyles } from './QuoteTableConfig.css';

export const QuoteTableConfig = () => {

  const classes = useStyles();

  const formatPrice = (price: number, currency: string) => {
    const roundedPrice = Math.round(price);
    return price ? `${CURRENCY_TYPE_LABEL[currency]}${roundedPrice}` : 'N/A';
  };

  const DashboardTableConfig : Column [] = [
    { id: 'id', label: 'Quote ID', sortable: true, orderingField: 'id', customRender: (quote: Quote) => (
      <Stack>
        <Link href={`/quotes/${quote.id}`} className={classes.quoteId} underline='none'><Typography variant='h5'>{quote.system_id}</Typography></Link>
        <div>{quote.shipper_quote_id}</div>
      </Stack>
    )},
    { id: 'expiration_date', label: 'Expiration Date', sortable: true, orderingField: 'expiration_date', customRender: (quote: Quote) => {
      const formattedDateTime = formatSeparatelyDateTimeWith12HourClock(quote.expiration_date);
      return (
        <Stack>
          <div>{formattedDateTime.date}</div>
          <div>{formattedDateTime.time}</div>
        </Stack>
      );
    }},
    { id: 'customer_name', label: 'Shipper', sortable: true, orderingField: 'customer_name', customRender: (quote: Quote) => (
      <div className={classes.customer}>{quote.customer_name}</div>
    )},
    { id: 'destinations', label: 'Destinations', sortable: false, customRender: (quote: Quote) => (
      <Stack direction='row' spacing={1}>
        <Stack className={classes.secondaryText}>
          <div>From:</div>
          <div>To:</div>
        </Stack>
        <Stack>
          <div>{quote.new_origin}</div>
          <div>{quote.new_destination}</div>
        </Stack>
      </Stack>
    ) },
    { id: 'stops', label: 'Stops', sortable: false, customRender: (quote: Quote) => (
      quote.lane.stops.length > 2 && <StopsTooltip stops={quote.lane.stops}/>
    )},
    { id: 'equipment_type', label: 'Equipment', sortable: true, orderingField: 'equipment_type'},
    { id: 'pickup_date', label: 'Pickup', sortable: true, orderingField: 'new_pickup_date', customRender: (quote: Quote) => formatDateToDashboardPageFormat(quote.new_pickup_date, true)},
    { id: 'delivery_date', label: 'Delivery', sortable: true, orderingField: 'new_delivery_date', customRender: (quote: Quote) => formatDateToDashboardPageFormat(quote.new_delivery_date, true)},
    { id: 'quoted_price', label: 'Quoted Price', sortable: true, orderingField: 'quoted_price', customRender: (quote: Quote) => formatPrice(quote.quoted_price, quote.currency)},
    { id: 'status', label: 'Status', sortable: false, orderingField: 'status', customRender: (quote: Quote) => (
      <StatusChip status={QUOTE_STATUS_LABELS[quote.status]}/>
    )},
  ];

  return DashboardTableConfig;
};