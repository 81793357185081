import { QUOTE_STATUS, CURRENCY_TYPE_LABEL} from 'enums';
import React from 'react';

export const getQuoteMargin = (quote) => {
  return quote.status === QUOTE_STATUS.WON && quote.margin && (
    quote.margin >= 0 ? <> (${Math.round(quote.margin)})</> : <> (-${Math.abs(Math.round(quote.margin))})</>
  );
};

export const getIntermodalRateTitle = (quoteId) => {
  const quoteIdStr = quoteId ? ` \u2022 ${quoteId}` : '';
  return `53' Intermodal Rate${quoteIdStr}`;
};

export const getDryVanRateTitle = (quoteId, totalRate, currency) => {
  const totalRateStr = totalRate ? ` \u2022 ${CURRENCY_TYPE_LABEL[currency]}${totalRate}` : '';
  return `${getIntermodalRateTitle(quoteId)}${totalRateStr}`;
};
