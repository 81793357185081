import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { Box } from '@material-ui/core';
import { FormikProps } from 'formik';

import { RULE_CONTEXT, RULE_CONTEXT_LABELS } from 'enums';
import { ConfirmationDialog, FormModalContent, SelectField } from 'components';
import {
  useDuplicateRulesMutation,
  useRuleDuplicationCustomerQuery,
} from 'services';
import { Customer, FormCustomer, FormValuesDuplicateRule, RuleDuplicationCustomers } from 'types';
import { getShipperRulesValidationSchema } from './validation';

import { ModalComponent } from '../../../components/Modals/ModalComponent/ModalComponent';

import { useStyles } from './CustomerDuplicateRulesModal.css';

type CustomerDuplicateRulesProps = {
  customer: Customer,
  onCancel: () => void,
  onDuplicateSuccess: () => void
};

export const CustomerDuplicateRulesModal = ({ customer, onCancel, onDuplicateSuccess }: CustomerDuplicateRulesProps) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const formRef = useRef<FormikProps<FormCustomer>>();

  const [ showConfirmDialog, setShowConfirmDialog ] = useState(false);
  const [ targetCustomers, setTargetCustomers ] = useState<number[]>([]);
  const [ confirmMessage, setConfirmMessage ] = useState('');
  const [ ruleContext, setRuleContext ] = useState<RULE_CONTEXT>(null);

  const { isLoading: isLoadingCustomers, data: ruleDuplicationCustomerQueryData } = useRuleDuplicationCustomerQuery(customer.id);
  const ruleDuplicationCustomers: RuleDuplicationCustomers[] = ruleDuplicationCustomerQueryData?.data?.map((customer) => {
    return {
      id: customer.id.toString(),
      label: customer.customer_name,
      hasPriceRules: customer.has_price_prediction_rules,
      hasMaxBuyRules: customer.has_max_buy_price_prediction_rules
    };
  });

  const ruleFieldMapping: Record<string, keyof RuleDuplicationCustomers> = {
    [RULE_CONTEXT.MAX_BUY_PRICE_PREDICTION]: 'hasMaxBuyRules',
    [RULE_CONTEXT.QUOTE_PRICE_PREDICTION]: 'hasPriceRules',
  };

  const ruleContextOptions = Object.entries(RULE_CONTEXT_LABELS).map(([ id, label ]) => (
    { id, label }
  )).filter(({ id }) =>
    (customer.has_max_buy_price_prediction_rules && id === RULE_CONTEXT.MAX_BUY_PRICE_PREDICTION) ||
    (customer.has_price_prediction_rules && id === RULE_CONTEXT.QUOTE_PRICE_PREDICTION)
  ).sort((a, b) => a.label.localeCompare(b.label));

  const {mutate: duplicateRules, isLoading: isDuplicateRulesLoading} = useDuplicateRulesMutation(queryClient, {
    onSuccess: () => {
      toast.success('Rules successfully duplicated!');
      // onCancel is called here because this method only sets the modal's opening flag to false
      // to close the modal after a successful action
      onCancel();
      onDuplicateSuccess();
    },
    onError: (error) => {
      toast.error(error.response.data.message);
    },
  });

  const initialValues: FormValuesDuplicateRule = {
    context: null,
    shippers: []
  };

  const onSubmit = (values: FormValuesDuplicateRule) => {
    const context = values.context;
    setRuleContext(context);
    const target_customers = [...values.shippers].map(Number);
    setTargetCustomers(target_customers);

    const ruleField = ruleFieldMapping[context];
    const customersWithRules = ruleDuplicationCustomers.filter(
      (customer) => customer?.[ruleField] && target_customers.includes(Number(customer?.id))
    ).map((customer) => customer?.label);

    if (customersWithRules.length > 0) {
      const customerNames = customersWithRules.join(', ');
      setConfirmMessage(`This action will ERASE and REPLACE rules of the following shippers: ${customerNames} with the rules of the selected shipper.\nProceed?`);
      setShowConfirmDialog(true);
    } else {
      duplicateRules({ customerId: customer.id, target_customers, context });
    }
  };

  return (
    <>
      <FormModalContent<FormValuesDuplicateRule>
        initialValues={initialValues}
        validationSchema={getShipperRulesValidationSchema}
        validateOnChange={true}
        enableDivider={false}
        innerRef={formRef}
        isActionInProgress={
          isLoadingCustomers ||
          isDuplicateRulesLoading
        }
        primaryButtonLabel={'Save'}
        onCancel={onCancel}
        onSubmit={onSubmit}>
        {({ values }) => (
          <Box className={classes.formControl}>
            <SelectField
              id='context'
              label='Type'
              placeholder='Choose type'
              options={ruleContextOptions}/>
            <SelectField
              id='shippers'
              label='Shippers'
              defaultValue={values.shippers}
              options={ruleDuplicationCustomers}
              placeholder='Choose shippers'
              allOption='All shippers'
              multiple={true} />
          </Box>
        )}
      </FormModalContent>

      <ModalComponent message={null} className={classes.rulesModalComponent} isOpen={showConfirmDialog} onCancel={() => setShowConfirmDialog(false)}>
        <ConfirmationDialog
          message={confirmMessage}
          onSubmit={() => duplicateRules({ customerId: customer.id, target_customers: targetCustomers, context: ruleContext })}
          onCancel={() => setShowConfirmDialog(false)}
          primaryButtonLabel='Proceed'
          cancelButtonLabel='Cancel' />
      </ModalComponent>
    </>
  );
};