import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { AccessControl, ActionButton, ConfirmationDialog, CreateUpdateRuleModal, FilterComponent, ModalComponent, TableComponent } from 'components';
import { useAuth } from 'contexts';
import { FILTER_PAGE_SOURCE } from 'enums';
import { checkPermissions, getDuplicatedRule, orderPriceRulesByPriority, setPageAppliedFilters } from 'helpers';
import { DeleteIcon, ReprioritizeIcon } from 'resources';
import { ExpressionEditorConfig, Filter, Identified, Rule, Ruleset, ShipperRuleFilter } from 'types';

import { RuleTableColumns } from './RuleTableConfig';
import { RuleTableMenu } from './RuleTableMenu';

import { useStyles } from './RuleTable.css';

export type Customer = {
  name: string
  isLoading: boolean
};

type RuleTableProps = {
  isSuccess: boolean,
  isFetching: boolean,
  isError: boolean,
  isLoading?: boolean,
  ruleset: Ruleset,
  ruleEditorConfig: ExpressionEditorConfig,
  handleSaveChanges: (payload: Ruleset) => void,
  permissions: string[],
  appliedFiltersAndSearch: ShipperRuleFilter,
  filterPageSource: FILTER_PAGE_SOURCE,
};

export const RuleTable = ({
  isSuccess,
  isFetching,
  isError,
  isLoading,
  ruleset,
  ruleEditorConfig,
  handleSaveChanges,
  permissions,
  appliedFiltersAndSearch,
  filterPageSource,
} : RuleTableProps) => {
  const classes = useStyles();
  const { currentUser } = useAuth();

  const mapSelectedFiltersToQuery = (search: string) => {
    return { search };
  };

  const [ search, setSearch ] = useState<string>(appliedFiltersAndSearch.search || '');
  const [ rules, setRules ] = useState<Ruleset>([]);
  const [ selectedRows, setSelectedRows ] = useState<Identified[]>([]);
  const [ showDeleteModalOpen, setShowDeleteModalOpen ] = useState<boolean>(false);
  const [ showCreateEditNewModal, setShowCreateEditNewModal ] = useState<boolean>(false);

  const orderOriginalPriceRulesByPriority = (rules: Ruleset) => {
    const orderedRules = orderPriceRulesByPriority(rules);
    setRules(filterRules(orderedRules));
    return orderedRules;
  };

  const filterRules = (rules: Ruleset, query?: Filter): Ruleset => {
    query = query || mapSelectedFiltersToQuery(search);
    return rules.filter(rule => rule.name.toLowerCase().includes(query.search.toLowerCase()));
  };

  const originalOrderedRules = useMemo(() => orderOriginalPriceRulesByPriority(ruleset ?? []), [ruleset]);

  useEffect(() => {
    if (isFetching || isLoading) {
      return;
    }
    const query = mapSelectedFiltersToQuery(search);
    filterPriceRules(originalOrderedRules, query);
  }, []);

  useEffect(() => {
    if (isFetching || isLoading) {
      return;
    }
    const query = mapSelectedFiltersToQuery(search);
    debounceFilters(originalOrderedRules, query);
  }, [search]);

  const filterPriceRules = (originalRules: Ruleset, query: Filter) => {
    setSelectedRows([]);
    setRules(filterRules(originalRules, query));
    setPageAppliedFilters(filterPageSource, query.search);
  };

  const debounceFilters = useCallback(
    debounce((originalRules, query) => {
      filterPriceRules(originalRules, query);
    }, 300),
    []
  );

  const reoderPriorityGroup = (rulesList: Ruleset, priority: number) => {
    let asciiCodeOrder = 'a'.charCodeAt(0);
    for (let i = 0; i < rulesList.length; i++) {
      if (rulesList[i].priority === priority) {
        rulesList[i].order = String.fromCharCode(asciiCodeOrder);
        asciiCodeOrder+=1;
      }
    }
  };

  const handleDragAndDrop = (sourceIndex: number, destinationIndex: number) => {
    if (sourceIndex === destinationIndex) {
      return;
    }
    const newList = [...originalOrderedRules];
    const sourceRule = rules[sourceIndex];
    const sourceRuleIndex = newList.indexOf(sourceRule);
    const destinationRule = rules[destinationIndex];
    const destinationRuleIndex = newList.indexOf(destinationRule);
    newList.splice(sourceRuleIndex, 1);
    newList.splice(destinationRuleIndex, 0, sourceRule);

    if (sourceRule.priority !== destinationRule.priority) {
      const initialSourcePriority = sourceRule.priority;
      sourceRule.priority = destinationRule.priority;
      reoderPriorityGroup(newList, initialSourcePriority);
    }
    reoderPriorityGroup(newList, destinationRule.priority);
    setSelectedRows([]);
    handleSaveChanges(newList);
  };

  const handleReprioritizeRules = () => {
    const newList = [...originalOrderedRules];
    let nextPriority = 1;
    let foundPriority = newList[0].priority;
    for (let i = 0; i < newList.length; i++) {
      if (newList[i].priority !== foundPriority) {
        nextPriority += 1;
        foundPriority = newList[i].priority;
      }
      newList[i].priority = nextPriority;
    }
    setSelectedRows([]);
    handleSaveChanges(newList);
  };

  const deleteRules = (itemToDelete: Identified[]) => {
    const newList = [...originalOrderedRules].filter(i => !itemToDelete.includes(i));
    setSelectedRows([]);
    handleSaveChanges(newList);
  };

  const createRule = (rule: Rule ) => {
    if (!rule.id) {
      rule.id = uuidv4();
    }
    rule.priority= originalOrderedRules.length > 0 ? (Math.max(...originalOrderedRules.map(value => value.priority)) + 1) : 1;
    const newList = [ ...originalOrderedRules, rule ];
    setSelectedRows([]);
    handleSaveChanges(newList);
  };

  const duplicateRule = (original: Rule) => {
    const duplicate = getDuplicatedRule(original, originalOrderedRules);
    const newList = [ ...originalOrderedRules, duplicate ];
    setSelectedRows([]);
    handleSaveChanges(newList);
  };

  const editRule = (rule: Rule ) => {
    let newList = [...originalOrderedRules];
    const found = newList.find(el => el.id === rule.id);
    found.priority = rule.priority;
    found.name = rule.name;
    found.test = rule.test;
    found.actions = rule.actions;
    found.order = rule.order;

    setRules(filterRules(newList));
    setSelectedRows([]);
    newList = orderPriceRulesByPriority(newList);
    handleSaveChanges(newList);
  };

  return (
    <>
      <FilterComponent
        filterPageSource={filterPageSource}
        searchText={search}
        setSearchText={setSearch}
        showFilters={false}>
        <Grid item className={classes.actionsWrapper}>
          <AccessControl permissions={permissions}>
            <ActionButton
              text='Reprioritize Rules'
              variant='default'
              colorVariant='white'
              disabled={rules.length < 2}
              handleClick={handleReprioritizeRules}
              startIcon={<ReprioritizeIcon />}
              className={classes.headerButton} />
            <ActionButton
              text='New Rule'
              variant='primary'
              disabled={isLoading || isFetching}
              isLoading={isLoading || isFetching}
              handleClick={() => setShowCreateEditNewModal(true)}
              startIcon={<FontAwesomeIcon icon={faPlus}/>}
              className={classes.headerButton}/>
          </AccessControl>
        </Grid>
      </FilterComponent>
      <TableComponent
        columns={RuleTableColumns}
        isLoading={isFetching}
        isSuccess={isSuccess}
        isError={isError}
        tableData={rules}
        areRowsSelectable={checkPermissions(currentUser.permissions, permissions)}
        isTablePaginated={false}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        defaultOrderBy={'priority'}
        defaultOrder={'asc'}
        isDragAndDropDisabled={!checkPermissions(currentUser.permissions, permissions)}
        handleDragAndDrop={handleDragAndDrop}
        bulkMenuConfig={[
          {
            icon: DeleteIcon,
            permissions: permissions,
            label: 'Delete Rules',
            handleClick: () => setShowDeleteModalOpen(true),
          },
        ]}
        renderMenu={(rule: Rule) => {
          return (
            <AccessControl permissions={permissions}>
              <RuleTableMenu
                rules={rules}
                rule={rule}
                ruleEditorConfiguration={ruleEditorConfig}
                permissions={permissions}
                handleDeleteRule={(item:Identified) => deleteRules([item])}
                handleDuplicateRule={duplicateRule}
                handleEditRule={editRule} />
            </AccessControl>
          );
        }} />
      <ModalComponent
        message={`Are you sure you want to delete ${selectedRows.length} rules?`}
        isOpen={showDeleteModalOpen}
        onCancel={() => setShowDeleteModalOpen(false)}>
        <ConfirmationDialog
          isActionInProgress={false}
          onSubmit={() => {
            deleteRules(selectedRows);
            setShowDeleteModalOpen(false);
          }}
          onCancel={() => setShowDeleteModalOpen(false)}/>
      </ModalComponent>

      <ModalComponent
        message='New Rule'
        isOpen={showCreateEditNewModal}
        modalWidth='xl'
        onCancel={() => setShowCreateEditNewModal(false)}
        disableEnforceFocus={true}>
        <CreateUpdateRuleModal rules={rules}
          ruleEditorConfiguration={ruleEditorConfig}
          onCancel={() => setShowCreateEditNewModal(false)}
          handleCreateEditRule={createRule}/>
      </ModalComponent>
    </>
  );
};