import React, { useEffect, useState } from 'react';
import { Checkbox, CircularProgress, ListItemText, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import { MenuProps, Option } from 'types';

import { useStyles } from './SelectFilter.css';

type SelectFilterProps = {
  defaultValue: unknown,
  options: Option[],
  onChange: (value: unknown) => void,
  multiple?: boolean,
  allOption?: string,
  className?: string,
  variant?: 'filled' | 'outlined' | 'standard',
  disableUnderline?: boolean,
  label?: React.ReactNode,
  placeholder?: string,
  isReadOnly?:boolean
};

export const SelectFilter = ({
  defaultValue,
  options,
  onChange,
  multiple,
  allOption,
  className,
  variant = 'outlined',
  disableUnderline = false,
  placeholder = '',
  label = undefined,
  isReadOnly = false,
} : SelectFilterProps) => {

  const classes = useStyles();
  const [ items, setItems ] = useState<Option[]>([]);
  const [ value, setValue ] = useState<unknown>(defaultValue);
  const [ displayValue, setDisplayValue ] = useState<string>('');

  const handleSelection = (e: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
    if (!multiple) {
      setValue(e.target.value);
      return;
    }

    const newValue = [...(e.target.value as string[])];
    if (Array.isArray(value) && value.includes('all')) {
      if (newValue.includes('all')) {
        setValue(value.filter((v: string) => !newValue.includes(v)));
      }
    } else {
      if (newValue.includes('all')) {
        setValue(items.map(c => c.id));
      } else {
        setValue(newValue);
      }
    }
  };

  useEffect(() => {
    let newDisplayValue = '';
    if (multiple && Array.isArray(value)) {
      newDisplayValue = value.includes('all')
        ? allOption
        : items.filter(item => value.includes(item.id)).map(item => item.label).join(', ');
    } else {
      newDisplayValue = value === 'all' || Number.isNaN(value)
        ? allOption
        : items.find(item => value === item.id)?.label;
    }

    setDisplayValue(newDisplayValue);
    onChange(value);
  }, [ JSON.stringify(value), JSON.stringify(items) ]);

  useEffect(() => {
    if (!options) {
      return;
    }

    const newOptions = [...options];
    if (allOption) {
      newOptions.unshift({ id: 'all', label: allOption });
    }
    setItems(newOptions);

    if (multiple && Array.isArray(defaultValue) && defaultValue.includes('all')) {
      setValue(newOptions.map(opt => opt.id));
    }
  }, [JSON.stringify(options)]);

  const menuProps : MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    autoFocus: true,
    getContentAnchorEl: null,
    classes: {
      paper: classes.selectPaper
    }
  };

  return (
    <Select
      className={clsx({
        [classes.select]: true,
        [className]: className,
      })}
      defaultValue={value}
      displayEmpty
      multiple={multiple}
      value={value}
      variant={variant}
      disableUnderline={disableUnderline}
      renderValue={() => displayValue && displayValue !== '' ? displayValue : <Typography className={classes.placeholder}>{placeholder}</Typography>}
      onChange={handleSelection}
      placeholder={placeholder}
      label={label}
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={menuProps}
      readOnly={isReadOnly}>
      {items.length > 0 ? items.map(item =>
        <MenuItem key={item.id} value={item.id} className={classes.selectMenuItem}>
          {multiple ?
            <>
              <Checkbox checked={Array.isArray(value) && value.includes(item.id)} />
              <ListItemText primary={item.label} />
            </> :
            item.label
          }
        </MenuItem>
      )
        :
        <MenuItem className={classes.circuralProgressStyle}><CircularProgress /></MenuItem>}
    </Select>
  );
};