import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, Box, InputLabel, Divider } from '@material-ui/core';
import { Grid, Pagination } from '@mui/material';
import { stringify } from 'qs';

import { CollapsibleWidget, SadStates, SelectFilter, WidgetLoadingState, WidgetNoResultsState } from 'components';
import { DEFAULT_ROWS_PER_PAGE, QUOTE_HISTORY_FILTER_OPTIONS, QUOTE_STATUS } from 'enums';
import { sortOptions } from 'helpers';
import { useQuoteHistoryQuery } from 'services';
import { Params } from 'types';
import { QuoteHistoryItem } from './QuoteHistoryItem/QuoteHistoryItem';

import { useStyles } from './QuoteHistory.css';

type QuoteHistoryProps = {
  pickupZip: string,
  deliveryZip: string,
  customer: number
};

export const QuoteHistory = ({ pickupZip, deliveryZip, customer } : QuoteHistoryProps) => {
  const { id } = useParams<Params>();
  const classes = useStyles();
  const [ customerFilter, setCustomerFilter ] = useState<number>(null);
  const [ wonFilter, setWonFilter ] = useState<number>(null);
  const [ zipFilter, setZipFilter ] = useState([]);
  const [ orderingFilter, setOrderingFilter ] = useState('-proximity');
  const [ page, setPage ] = useState<number>(1);
  const [ filter, setFilter ] = useState({
    customer: customerFilter,
    stopping_points: zipFilter.join(','),
    status: wonFilter,
  });

  const { isError, isLoading, isSuccess, data: historyData } = useQuoteHistoryQuery(Number(id), stringify(filter), ((page-1) * DEFAULT_ROWS_PER_PAGE), orderingFilter);
  const { data: history } = historyData || {};
  useEffect(() => {
    const query = {
      customer: customerFilter,
      stopping_points: zipFilter.join(','),
      status: wonFilter,
    };
    setFilter(query);
    setPage(1);
  }, [ customerFilter, wonFilter, zipFilter, orderingFilter ]);

  const handleZipFilter = () => {
    zipFilter.length ? setZipFilter([]) : setZipFilter([ pickupZip, deliveryZip ]);
  };

  const handleCustomerFilter = () => {
    customerFilter ? setCustomerFilter(null) : setCustomerFilter(customer);
  };

  const handleWonFilter = () => {
    wonFilter ? setWonFilter(null) : setWonFilter(QUOTE_STATUS.WON);
  };

  return (
    <CollapsibleWidget headerTitle='Matching Quote History'>
      <Grid container spacing={2} wrap='wrap' className={classes.container}>
        <Grid xs={12} sm={8}>
          <Box className={classes.historyFiltersWrapper}>
            <Box className={classes.headerItem}>
              <Checkbox id='zipFilter' className={classes.checkbox} onChange={handleZipFilter} disabled={isLoading} />
              <InputLabel htmlFor='zipFilter' className={classes.headerItemText} disabled={isLoading}>5 zip only</InputLabel>
            </Box>
            <Box className={classes.headerItem}>
              <Checkbox id='customerFilter' className={classes.checkbox} onChange={handleCustomerFilter} disabled={isLoading} />
              <InputLabel htmlFor='customerFilter' className={classes.headerItemText} disabled={isLoading}>Shipper match</InputLabel>
            </Box>
            <Box className={classes.headerItem}>
              <Checkbox id='wonFilter' className={classes.checkbox} onChange={handleWonFilter} disabled={isLoading} />
              <InputLabel htmlFor='wonFilter' className={classes.headerItemText} disabled={isLoading}>Won</InputLabel>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} sm={4}>
          <SelectFilter
            defaultValue={QUOTE_HISTORY_FILTER_OPTIONS[0].id}
            options={sortOptions(QUOTE_HISTORY_FILTER_OPTIONS)}
            onChange={(value: string) => setOrderingFilter(value)}
            className={classes.selectItem}/>
        </Grid>
      </Grid>
      <SadStates states={[
        {
          when: isSuccess && history.results.length === 0,
          render: () => <WidgetNoResultsState message='No quotes available.'/>
        },
        {
          when: isError,
          render: () => <WidgetNoResultsState message='Something went wrong when trying to get matching quotes.'/>
        },
      ]}>
        <SadStates states={[
          {
            when: isLoading,
            render: () => <WidgetLoadingState/>
          },
        ]}>
          {history?.results.map((h, index: number) => (
            <div key={h.id}>
              <Divider className={classes.divider}/>
              <QuoteHistoryItem quote={h}/>
              {index === history?.results.length - 1 && <Divider className={classes.divider}/>}
            </div>
          ))}
        </SadStates>
        <Box className={classes.paginationContainer}>
          <Pagination
            className={classes.pagination}
            size='small'
            shape='rounded'
            variant='outlined'
            count={Math.ceil(history?.count / DEFAULT_ROWS_PER_PAGE) || 0}
            onChange={(e, value) => setPage(value)}
            page={page}/>
        </Box>
      </SadStates>
    </CollapsibleWidget>
  );
};