import React from 'react';
import { Card, CardContent, Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { CURRENCY_TYPE_LABEL } from 'enums';
import { SoftmodalCharge, SoftmodalRate } from 'types';

import { InfoLine } from '../InfoLine';

import { useStyles } from '../../QuoteDetailsPage.css';

type SoftmodalRateContentProps = {
  rates: SoftmodalRate[] | null,
  error: string,
  currency: string,
  className?: string,
};

export const SoftmodalRateContent = ({ rates, error, currency, className} : SoftmodalRateContentProps) => {
  const classes = useStyles();

  return (
    <>
      {Object.values(rates).map((rate: SoftmodalRate, indexRate: number) => (
        <Card key={indexRate} style={{ boxShadow: 'none' }}>
          <CardContent
            className={clsx({
              [classes.softmodalDryVanRatesRate]: className,
              [className]: className,
            })}>
            <Typography variant='h4' className={classes.cardSubtitle}>
              Rate {indexRate+1}
            </Typography>
          </CardContent>
          <InfoLine text='Total Rate' value={`${CURRENCY_TYPE_LABEL[currency]}${rate?.total}`} className={className} />
          <Divider className={classes.cardContentDivider} />
          <InfoLine text='Transit time (in days)' value={rate?.transit && String(rate?.transit)} className={className} />
          <Divider className={classes.cardContentDivider} />
          {Object.values(rate?.charges).map((charge: SoftmodalCharge, indexCharge: number) => (
            <Card key={indexCharge}>
              <CardContent className={className}>
                <Typography variant='h4' className={classes.cardSubtitle}>
                    Charge {indexCharge+1}
                </Typography>
              </CardContent>
              <InfoLine text='Name' value={charge.name} className={className} />
              <Divider className={classes.cardContentDivider} />
              <InfoLine text='Amount' value={`${CURRENCY_TYPE_LABEL[currency]}${charge.amount}`} className={className} />
              <Divider className={classes.cardContentDivider} />
              <InfoLine text='FSC' value={charge.fsc ? 'Yes' : 'No'} className={className} />
            </Card>
          ))}
        </Card>))}
      {error && error !=='' && (
        <>
          <Divider />
          <InfoLine text='Error' asHTML={true} value={error} className={className} />
        </>)
      }
    </>
  );
};